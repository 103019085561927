import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyload from "vue-lazyload";
 
Vue.use(VueLazyload);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// title
// const defaultTitle = '默认 title'
// router.beforeEach((to, from, next) => {
//   document.title = to.meta.title ? to.meta.title : defaultTitle
//   next()
// })