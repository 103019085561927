import Vue from 'vue'
import VueRouter from 'vue-router'
// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: ()=>import("../views/index.vue")
  },
  {
    path: '/jituangaikuo',
    name: 'jituangaikuo',
    component: ()=>import("../views/jituangaikuo.vue")
  },
  {
    path: '/keji',
    name: 'keji',
    component: ()=>import("../views/keji.vue")
  },
  {
    path: '/youjiao',
    name: 'youjiao',
    component: ()=>import("../views/youjiao.vue")
  },
  {
    path: '/jinrong',
    name: 'jinrong',
    component: ()=>import("../views/jinrong.vue")
  },
  {
    path: '/dianshang',
    name: 'dianshang',
    component:()=>import("../views/dianshang.vue")
  },
  {
    path: '/xinwen',
    name: 'xinwen',
    component: ()=>import("../views/news.vue"),
    //news,
    children: [{
        path: '',
        component: ()=>import("../views/News/GroupNews.vue"),
        //GroupNews,
        name: 'GroupNews',
      },
      {
        path: 'MediaCoverage',
        component: ()=>import("../views/News/MediaCoverage.vue"),
        //MediaCoverage,
        name: 'MediaCoverage',
      },
      {
        path: 'TradeNews',
        name: 'TradeNews',
        component:()=>import('../views/News/TradeNews.vue'),
      },

    ],
  },
  {
    path: '/newsxiangqing0',
    name: 'newsDetal',
    component: ()=>import("../views/newsxiangqing.vue")
  }, {
    path: '/newsxiangqing1',
    name: 'newsDetal1',
    component: ()=>import("../views/NewsDetals/newsxiangqing1.vue")
  },
  {
    path: '/newsxiangqing2',
    name: 'newsDetal2',
    component:()=>import("../views/NewsDetals/newsxiangqing2.vue")
  },
  // {
  //   path: '/MediaCoverage0',
  //   name: 'MediaCoverage0',
  //   component:()=>import("../views/NewsDetals/MediaCoverage0.vue")
  // },
  // {
  //   path: '/MediaCoverage1',
  //   name: 'MediaCoverage1',
  //   component: ()=>import('../views/NewsDetals/MediaCoverage1.vue')
  // }, {
  //   path: '/TradeNews0',
  //   name: 'TradeNews0',
  //   component: ()=>import('../views/NewsDetals/TradeNews0.vue')
  // },
  // {
  //   path: '/TradeNews1',
  //   name: 'TradeNews1',
  //   component: ()=>import("../views/NewsDetals/TradeNews1.vue")
  // },
  {
    path: '/zhaopinxinxi0',
    name: 'zhaopinxinxi0',
    component: ()=>import("../views/zhaopinxinxi0.vue")
  },
  {
    path: '/zhaopinxinxi1',
    name: 'zhaopinxinxi1',
    component:()=>import("../views/Recruit-info/zhaopinxinxi1.vue")
  },
  {
    path: '/lianxi',
    name: 'lianxi',
    component:()=>import("../views/lianxiwomen.vue")
  },
  {
    path: '/zhaopinxiangqing0_0',
    name: 'zhaopinxiangqing0_0',
    component: ()=>import("../views/Recruitment-details/zhaopinxiangqing0-0.vue"),
  },
  {
    path: '/zhaopinxiangqing0_1',
    name: 'zhaopinxiangqing0_1',
    component: ()=>import("../views/Recruitment-details/zhaopinxiangqing0-1.vue")
  }, {
    path: '/zhaopinxiangqing1_0',
    name: 'zhaopinxiangqing1_0',
    component: ()=>import("../views/Recruitment-details/zhaopinxiangqing1-0.vue")
  }, {
    path: '/zhaopinxiangqing1_1',
    name: 'zhaopinxiangqing1_1',
    component:()=>import("../views/Recruitment-details/zhaopinxiangqing1-1.vue")
  }
]

const router = new VueRouter({
  routes,
  // 跳转页面后 回到顶部
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router